import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  base: __dirname,
  routes
})

/**
 * Authentification Checks
 */
router.beforeEach((to, from, next) => {
  const app = router.app

  if (app.$cookie.get('counseling-token')) {
    if (to.meta.disabled) {
      next({ name: 'index' })
    }
    if (to.name === 'index') {
      next({ name: 'dialogs' })
      return
    }

    if (app.$store.getters.hasUnsavedChanges) {
      app.$store.commit('setConfirmOpen', true)
      app.$store.commit('setInterruptedRoute', to.fullPath)
      return
    }
    // if (app.$store.getters.hasActiveChat && to.name !== 'liveChat') {
    //   app.$store.commit('setConfirmOpen', true)
    //   app.$store.commit('setInterruptedRoute', to.fullPath)
    //   return
    // }
    next()
    return
  }
  if (!to.meta.requiresAuth) {
    next()
    return
  }
  next({
    name: 'index'
  })
})

export default router

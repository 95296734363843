export default {
  getters: {
    announcement: (state, getters) => getters['entities/announcement/query']().first()
  },
  actions: {
    storeAnnouncement: ({ dispatch }, val) => {
      dispatch('entities/announcement/create', { data: val })
    }
  }
}

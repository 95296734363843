// EXAMPLE:
// export const foo = (state, val) => {
//   state.foo = val
// }
import { state as initialState, emptyMessage } from './state'

const mergeObj = (to, from) => {
  const res = Object.assign({}, to)
  for (const key in res) {
    if (Object.prototype.hasOwnProperty.call(from, key)) {
      res[key] = from[key]
    }
  }
  return res
}

export const setOffcanvas = (state, val) => {
  state.offcanvasOpen = val
}

export const setDialogOpen = (state, val) => {
  state.dialogOpen = val
}
export const setChatDialogOpen = (state, val) => {
  state.chatDialogOpen = val
}
export const setConfirmOpen = (state, val) => {
  state.confirmOpen = val
}

export const resetEditMessages = (state, msg = { subject: '', body: '' }) => {
  const editMessage = Object.assign({}, emptyMessage, msg)
  const initialEditMessage = Object.assign({}, emptyMessage, msg)
  // console.log(msg) // eslint-disable-line
  state.editMessage = editMessage
  state.initialEditMessage = initialEditMessage
}

export const setEditMessage = (state, val) => {
  state.editMessage = mergeObj(state.editMessage, val)
}

export const setInitialEditMessage = (state, val) => {
  state.initialEditMessage = mergeObj(state.initialEditMessage, val)
}

export const setMessageEditorOpen = (state, val) => {
  state.messageEditorOpen = val
}

export const setDialogRead = (state, { index, read }) => {
  state.dialogs[index].read = read
}

export const setDialogsLoading = (state, val) => {
  state.dialogsLoading = val
}

export const toggleDialogLoading = (state, val) => {
  state.dialogLoading.push(val)
}

export const untoggleDialogLoading = (state, val) => {
  state.dialogLoading.splice(state.dialogLoading.indexOf(val), 1)
}

export const storeDialog = (state, val) => {
  state.dialog = val
}

export const storeDialogs = (state, val) => {
  state.dialogs = val
}

export const storeSentMessages = (state, val) => {
  state.sentMessages = val
}

export const setUserInfo = (state, val) => {
  state.userInfo = val
}

export const setInterruptedRoute = (state, interruptedRoute) => {
  state.interruptedRoute = interruptedRoute
}
/**
 * Chat
 */
export const setCurrentChatEvent = (state, currentChatEvent) => {
  state.currentChatEvent = currentChatEvent
}
export const setCurrentUserChat = (state, currentUserChat) => {
  state.currentUserChat = currentUserChat
}

export const setNewChatMessage = (state, val) => {
  state.newChatMessage = val
}

export const setTypingState = (state, val) => {
  state.typingState = val
}

export const setPageHidden = (state, val) => {
  state.isPageHidden = val
}

/**
 * Reset Global State
 */
export const resetState = (state) => {
  const { currentChatEvent } = state
  Object.assign(state, initialState(), { currentChatEvent })
}

export const setPageDataLoading = (state, val) => {
  state.pageDataLoading = val
}

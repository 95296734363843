/**
 * Routes File
 */
const Account = () => import('templates/Account/Account')
const ChangePassword = () => import('templates/ChangePassword/ChangePassword')
const Dialogs = () => import('templates/Dialogs/Dialogs')
const ChatDialogs = () => import('templates/ChatDialogs/ChatDialogs')
const DialogWrapper = () => import('organisms/DialogWrapper/DialogWrapper')
const ChatDialogWrapper = () => import('organisms/ChatDialogWrapper/ChatDialogWrapper')
const Gdpr = () => import('organisms/Forms/Gdpr/Gdpr')
const Help = () => import('templates/Help/Help')
const NewDialog = () => import('templates/Dialogs/NewDialog')
const Register = () => import('templates/Register/Register')
const Sent = () => import('templates/Sent/Sent')
const Userlogin = () => import('templates/Userlogin/Userlogin')
const Simplecontent = () => import('templates/Simplecontent/Simplecontent')
const Feedback = () => import('templates/Feedback/Feedback')
const TheWrapper = () => import('templates/TheWrapper/TheWrapper')
const LiveChat = () => import('templates/LiveChat/LiveChat')
const LoginLanding = () => import('templates/LoginLanding/LoginLanding')

const chatDisabled = !!window.settings.chatDisabled

export default [
  {
    path: '/',
    name: 'index',
    component: Userlogin,
    meta: {
      requiresAuth: false
    },
    alias: ['/index', '/home', '/login', '*']
  },
  {
    name: 'forgotUsername',
    path: '/forgot-username',
    component: Userlogin,
    meta: {
      requiresAuth: false
    }
  },
  {
    name: 'forgotPassword',
    path: '/forgot-password',
    component: Userlogin,
    meta: {
      requiresAuth: false
    },
    alias: ['/reset-password']
  },
  {
    name: 'resetPassword',
    path: '/reset-password/:token',
    component: Userlogin,
    meta: {
      requiresAuth: false
    }
  },
  {
    name: 'resendConfirmMail',
    path: '/resend-confirm',
    component: Userlogin,
    meta: {
      requiresAuth: false
    }
  },
  {
    name: 'register',
    path: '/register',
    component: Register,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/landing',
    name: 'loginLanding',
    component: LoginLanding,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cybermobbing',
    component: TheWrapper,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        name: 'dialogs',
        path: 'dialogs',
        component: Dialogs,
        meta: {
          requiresAuth: true,
          detailsRoute: 'dialog'
        },
        children: [
          {
            name: 'dialog',
            path: 'dialog/:dialogId',
            component: DialogWrapper,
            meta: {
              requiresAuth: true,
              parentRoute: 'dialogs'
            }
          },
          {
            name: 'newDialog',
            path: 'new/:dialogId?',
            component: NewDialog,
            meta: {
              requiresAuth: true,
              parentRoute: 'dialogs',
              detailsRoute: 'dialog'
            }
          }
        ]
      },
      {
        name: 'chatDialogs',
        path: 'chatDialogs',
        component: ChatDialogs,
        meta: {
          requiresAuth: true,
          detailsRoute: 'chatDialog',
          disabled: chatDisabled
        },
        children: [
          {
            name: 'chatDialog',
            path: 'chatDialog/:dialogId',
            component: ChatDialogWrapper,
            meta: {
              requiresAuth: true,
              parentRoute: 'chatDialogs'
            }
          }
        ]
      },
      {
        name: 'sent',
        path: 'sent',
        component: Sent,
        meta: {
          requiresAuth: true,
          detailsRoute: 'sentDialog'
        },
        children: [
          {
            path: 'dialog/:dialogId/message/:messageId',
            name: 'sentDialog',
            component: DialogWrapper,
            meta: {
              requiresAuth: true,
              parentRoute: 'sent'
            }
          }
        ]
      },
      {
        name: 'account',
        path: 'account',
        component: Account,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'gdpr',
        path: '/gdpr',
        component: Gdpr,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'changePassword',
        path: 'change-password',
        component: ChangePassword,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'support',
        path: 'support',
        component: Help,
        meta: {
          requiresAuth: false
        }
      },
      {
        name: 'content',
        path: 'content/:contentName',
        component: Simplecontent,
        meta: {
          requiresAuth: false
        }
      },
      {
        name: 'feedback',
        path: 'feedback',
        component: Feedback,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'liveChat',
        path: 'live-chat',
        component: LiveChat,
        meta: {
          requiresAuth: true,
          disabled: chatDisabled
        }
      }
    ]
  }
]

import {
  Alert,
  Button,
  ButtonGroup,
  Checkbox,
  Col,
  Collapse,
  CollapseItem,
  Dialog,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Form,
  FormItem,
  Input,
  Loading,
  Message,
  MessageBox,
  Option,
  Radio,
  RadioGroup,
  Select,
  Tooltip
} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import lang from 'element-ui/lib/locale/lang/de'
import locale from 'element-ui/lib/locale'

export const init = (vueInstance) => {
  // element-ui localization
  locale.use(lang)

  vueInstance.use(Alert)
  vueInstance.use(Button)
  vueInstance.use(ButtonGroup)
  vueInstance.use(Checkbox)
  vueInstance.use(Col)
  vueInstance.use(Collapse)
  vueInstance.use(CollapseItem)
  vueInstance.use(Dialog)
  vueInstance.use(Dropdown)
  vueInstance.use(DropdownItem)
  vueInstance.use(DropdownMenu)
  vueInstance.use(Form)
  vueInstance.use(FormItem)
  vueInstance.use(Input)
  vueInstance.use(Loading)
  vueInstance.use(Option)
  vueInstance.use(Radio)
  vueInstance.use(RadioGroup)
  vueInstance.use(Select)
  vueInstance.use(Tooltip)

  vueInstance.prototype.$msgbox = MessageBox
  vueInstance.prototype.$alert = MessageBox.alert
  vueInstance.prototype.$confirm = MessageBox.confirm
  vueInstance.prototype.$prompt = MessageBox.prompt
  vueInstance.prototype.$message = Message
}



